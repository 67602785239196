.bottom-shadow {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgb(13, 13, 13, 1) 40%
  );
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.top-shadow {
  background: linear-gradient(
    90deg,
    rgba(0, 150, 136, 0) 0%,
    rgb(13, 13, 13, 1) 25%
  );
  height: 100%;
  width: 120%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.img-container {
  width: 50%;
  display: flex;
  align-items: center;
  position: relative;
}

.copy {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  font-size: 24px;
  margin-left: 5%;
  text-align: left;
  h2 {
    font-size: 50px;
    margin-top: 0;
  }
}

img {
}

.scroll-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  /* This is needed to prevent the image from "jittering" partway through the transition */
  overflow: hidden;
}
